import { UserStore } from '../../stores';
import array from 'lodash/array';

export const addOrMergeFeedPost = (post, state) => {
  const feedItems = state.feed || [];
  // FIXME Better way to do this?
  for (let i = 0; i < feedItems.length; i++) {
    if (feedItems[i].group_post) {
      if (parseInt(feedItems[i].group_post.id) === parseInt(post.id)) {
        console.log('Found in feed! Updating!');
        // eslint-disable-next-line no-param-reassign
        feedItems[i] = { ...post };
        return;
      }
    }
  }
  console.log('Adding to beginning');
  feedItems.unshift({ ...post, id: -1, type: 'group_post', group_post: post, user: UserStore.getRawState().user });
};

export const addOrMergePost = (post, state) => {
  console.log('Updating group store for post:');
  console.log(post);
  const groupId = post.group_id;
  const groupPosts = state.posts[groupId] || [];
  // FIXME Better way to do this?
  for (let i = 0; i < groupPosts.length; i++) {
    if (parseInt(groupPosts[i].id) === parseInt(post.id)) {
      // console.log('Found in list! Updating!');
      // console.log(post);
      // console.log('Original post:');
      // console.log(groupPosts[i]);
      // eslint-disable-next-line no-param-reassign
      groupPosts[i] = { ...post };
      return;
    }
  }
  console.log('Adding to beginning');
  groupPosts.unshift(post);
};

export const mergePosts = (posts, state) => {
  const groupId = posts[0].group_id;
  const existingPosts = state.posts[groupId] || [];
  state.posts[groupId] = array.unionBy(existingPosts, posts, 'id');
};

export const removePost = (post, state) => {
  console.log('removePost');
  console.log(post);
  const groupId = post.group_id;
  const groupPosts = state.posts[groupId] || [];
  console.log(JSON.stringify(groupPosts));
  // for (let i = 0; i < groupPosts.length; i++) {
  //   if (parseInt(groupPosts[i].id) === parseInt(post.id)) {
  //     console.log('Found! Removing!');
  //     console.log(JSON.stringify(groupPosts[i]));
  //     groupPosts.splice(i, 1);
  //     return;
  //   }
  // }
  state.posts[groupId] = groupPosts.filter((p) => parseInt(p.id) !== parseInt(post.id));
};

export const removeFromFeed = (post, state) => {
  console.log('removeFromFeed');
  console.log(post);
  const feedItems = state.feed || [];
  for (let i = 0; i < feedItems.length; i++) {
    if (feedItems[i].type === 'group_post') {
      if (parseInt(feedItems[i].group_post.id) === parseInt(post.id)) {
        console.log('Found! Removing!');
        feedItems.splice(i, 1);
        return;
      }
    }
  }
  state.feed = feedItems;
  // state.feed = feedItems.filter((feedItem) => parseInt(feedItem.group_post.id) !== parseInt(post.id));
};
