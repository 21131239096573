import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { isAndroid, isIOS } from 'react-device-detect';
import { Button as MaterialButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import IosAlert from '../../components/IosModal';
import Icon from '../../components/Icon';
import Placeholder from '../../../config/placeholder.config';
import { config } from '../../settings';
import ErrorLogger from '../../utils/errorLogger';
import { requestHumidors as requestHumidorsAction } from '../Humidor/actions';
import { selectAllHumidors } from '../Humidor/selectors';
import ModalDialog from '../../components/ModalDialog';

function HumidorEditor(props) {
  const [humidorName, setHumidorName] = useState(null);
  const renderTitle = () => (props.humidor ? 'Edit Humidor Details' : 'Add New Humidor');

  useEffect(() => {
    setHumidorName(props.humidor ? props.humidor.name : null);
  }, [props.humidor]);

  const onSave = () => {
    console.log('Saving humidor...');
    if (props.humidor && props.humidor.id) {
      const updatedHumidor = { ...props.humidor };
      updatedHumidor.name = humidorName;
      axios.put(`${config.apiEndPoint}/humidors/${updatedHumidor.id}`, updatedHumidor).then((humidor) => {
        console.log('Successfully updated humidor');
        console.log(humidor.data);
        props.requestHumidors(props.auth.user.id);
        if (typeof props.onClose === 'function') {
          props.onClose(humidor.data);
        }
      }).catch((err) => {
        console.log(err);
      });
    } else {
      axios.post(`${config.apiEndPoint}/humidors`, {
        name: humidorName,
        is_default: props.humidors ? props.humidors.length > 0 : true,
        user: props.auth.user,
      }).then((response) => {
        console.log('Successfully created humidor');
        console.log(response.data);
        props.requestHumidors(props.auth.user.id);
        if (typeof props.onClose === 'function') {
          props.onClose(response.data);
        }
      }).catch((err) => {
        if (err.response.status === 304) {
          ModalDialog.show({
            title: 'Unable to create humidor',
            message: 'A virtual humidor with this name already exists. Please enter a new name.',
            buttons: [{
              label: 'Dismiss',
              onClick: () => {
                ModalDialog.close();
              },
            }],
          });
        } else {
          console.log(err);
          ErrorLogger.captureException(err);
        }
      });
    }
  };

  const onDelete = () => {
    axios.delete(`${config.apiEndPoint}/humidors/${props.humidor.id}`).catch((err) => ErrorLogger.captureException(err));
  };

  const renderContent = () => (
    <div style={{ display: 'flex' }}>
      <Avatar
        style={{ marginRight: 10 }}
        alt={props.humidor ? props.humidor.name : 'New Humidor'}
        src={props.humidor ? (props.humidor.image_url || Placeholder.covers.humidor) : Placeholder.covers.humidor}
      />
      <input placeholder="Humidor name" className="form-control" value={humidorName} onChange={(event) => setHumidorName(event.target.value)} />
    </div>
  );

  if (isIOS) {
    let items = [{
      label: 'Cancel',
      onClick: props.onClose,
    }, {
      label: 'Save',
      onClick: props.onSave || onSave,
    }];
    if (props.humidor && (props.humidor.is_default !== true)) {
      items = [{
        label: 'Delete',
        style: { color: 'rgb(239, 81, 100)' },
        onClick: props.onDelete || onDelete,
      }, {
        label: 'Save',
        onClick: props.onSave || onSave,
      }, {
        label: 'Cancel',
        style: { fontWeight: 600 },
        onClick: props.onClose,
      }];
    }
    return (
      <IosAlert
        show={props.show}
        title={renderTitle()}
        content={renderContent()}
        buttons={items}
        onDismiss={() => {}}
      />
    );
  }

  if (isAndroid) {
    // FIXME From props? Build from an array of objects?
    const actions = [
      <MaterialButton
        primary
        onClick={props.onClose}
      >
        {'Cancel'}
      </MaterialButton>,
      <MaterialButton
        primary
        onClick={props.onSave || onSave}
      >
        {'Save'}
      </MaterialButton>,
    ];
    if (props.humidor && (props.humidor.is_default !== true)) {
      actions.unshift(<MaterialButton
        style={{ color: '#ef5164' }}
        onClick={props.onDelete || onDelete}
      >
        {'Delete'}
      </MaterialButton>);
    }
    return (
      <Dialog
        title={renderTitle()}
        actions={actions}
        modal={false}
        open={props.show}
        onClose={props.onClose}
      >
        <DialogTitle id="alert-dialog-title">{renderTitle()}</DialogTitle>
        <DialogContent>
          {renderContent()}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Modal
      isOpen={props.show}
      toggle={props.toggleOpen}
      style={{ maxWidth: 600 }}
      fade
    >
      <div className="modal-header">
        {renderTitle()}
        <Button className="close" color="" onClick={props.onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        {renderContent()}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.onClose}>Close</Button>
        <Button color="primary" onClick={props.onSave || onSave}>Save</Button>
        {props.humidor && (props.humidor.is_default !== true) && (
          <Button color="danger" onClick={props.onDelete || onDelete}>Delete</Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
    humidors: selectAllHumidors()(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestHumidors: (userId, callback) => dispatch(requestHumidorsAction(userId, callback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HumidorEditor);
